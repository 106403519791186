import React, { Component } from 'react';

import linked from '../Assets/linked.svg';
import git from '../Assets/git.svg';
class Footer extends Component {
    state = {  } 
    render() { 
        return (
            <div id='footer'>

                <div className='footer-column'>
                    <h4 className='foot-txt' style={{color: "white"}}>www.christinabui.net</h4>
                    <a className='foot-txt' href='mailto:ctbui.uyen@gmail.com' style={{color: "white"}}><h4>ctbui.uyen@gmail.com</h4></a>
               
                </div>
                <h4 className='foot-txt artwork-wm' style={{color: "white"}}>Artwork drawn by Christina Bui</h4>
                <div className='foot-logos'>
                          <a href='https://github.com/cttub' target='_blank'>  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 58" fill="currentColor">
            <path d="M31.5084 0.00184164C14.102 0.00184164 0 13.2011 0 29.4685C0 42.4946 9.02716 53.5354 21.538 57.4305C23.1193 57.7086 23.6904 56.797 23.6904 56.0088C23.6904 55.3089 23.6727 53.4581 23.6609 51.0013C14.8917 52.7766 13.0445 47.0399 13.0445 47.0399C11.6089 43.6438 9.5372 42.7322 9.5372 42.7322C6.68569 40.9108 9.76367 40.9495 9.76367 40.9495C12.9224 41.1502 14.5845 43.979 14.5845 43.979C17.3946 48.4874 21.9574 47.1835 23.7633 46.4247C24.0389 44.526 24.8601 43.2202 25.7542 42.4928C18.7593 41.7543 11.4041 39.222 11.4041 27.9307C11.4041 24.706 12.625 22.0797 14.6435 20.0171C14.295 19.2786 13.2276 16.2767 14.9212 12.2176C14.9212 12.2176 17.5581 11.4312 23.586 15.2398C26.1087 14.586 28.7869 14.26 31.4631 14.2435C34.1413 14.26 36.8176 14.5878 39.3402 15.2398C45.3327 11.4312 47.9676 12.2176 47.9676 12.2176C49.6592 16.2767 48.5919 19.2767 48.2847 20.0171C50.2835 22.0797 51.5044 24.7078 51.5044 27.9307C51.5044 39.2478 44.1393 41.7432 37.1366 42.4633C38.2315 43.3455 39.2575 45.1595 39.2575 47.9128C39.2575 51.854 39.2181 55.029 39.2181 55.9885C39.2181 56.7547 39.7695 57.6755 41.3922 57.379C53.9956 53.5244 63.0129 42.4744 63.0129 29.4667C63.0129 13.1992 48.9109 0 31.5045 0L31.5084 0.00184164Z"/>
            </svg></a>
            <a href='https://www.linkedin.com/in/cttub/' target='_blank'>   <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 58 53" fill="currentColor">
                            <path d="M12.8642 52.467H0.95024V17.4378H12.8642V52.467ZM6.90002 12.6595C3.09085 12.6595 0 9.77761 0 6.29979C0 2.82198 3.08879 0 6.90002 0C10.7112 0 13.8 2.8201 13.8 6.29979C13.8 9.77761 10.7092 12.6595 6.90002 12.6595ZM57.4536 52.467H45.5664V35.4152C45.5664 31.3509 45.4761 26.1398 39.3724 26.1398C33.1784 26.1398 32.2281 30.5546 32.2281 35.1229V52.467H20.3265V17.4378H31.752V22.216H31.9182C33.5088 19.4634 37.3939 16.559 43.1898 16.559C55.2473 16.559 57.4639 23.8088 57.4639 33.2248V52.467H57.4536Z" />
                            </svg></a>
                </div>
             
            </div>
        );
    }
}   
 
export default Footer;